.mobileBlockPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-family: Nunito;
    display: none;
    margin-top: 25%; 
}

.mobileBlockPage h1 {
    color: white;
    font-size: 18px;
    width: 90%;
    text-align: center;
    margin-top: 120px;
}

.mobileBlockPage a {
    color: white;
    font-size: 21px;
    width: 150px;
    height: 60px;
    text-align: center;
    border: 2px solid white;
    border-radius: 25px;
    text-decoration: none;
    font-family: "Nunito";
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@media screen and (max-width: 440px) {
    .mobileBlockPage {
        display: flex;
    }

    .snakeGame {
        display: none !important;
    }

    /* header {
        display: none;
    } */

    .cubeGame {
        display: none !important;
    }

    .tictactoeGame {
        display: none !important;
    }
}