.mainAIPage {
    width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background-color: #F3EFE0;
    font-family: "Nova Mono", monospace !important;
    font-weight: 400;
    font-style: normal;
}

.hide {
    display: none !important;
}

.mainAIPage .left {
    width: 30%;
    height: 95%;
    margin-right: 5px;
    border-radius: 25px;
}

.mainAIPage h2 {
    color: #434242;
}

.mainAIPage .left img {
    width: 100%;
    height: 83%;
    object-fit: cover;
    object-position: top;
    border-radius: inherit;
    margin-bottom: 2%;
}

.mainAIPage .right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    height: 95%;
    margin-left: 5px;

}

.mainAIPage .messages {
    width: 100%;
    height: 83%;
    margin-bottom: 2%;
    border-radius: 25px;
    background-color: #434242;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    border: #434242 1px solid;
    text-transform: uppercase;


}

.mainAIPage .textField {
    width: 100%;
    height: 15%;
    display: flex;
}

.mainAIPage .input {
    width: 79% !important;
    margin-right: 1%;
    border-radius: inherit;
    font-size: 30px;
    border-radius: 25px;
    color: black;
    text-transform: uppercase;
    padding-left: 30px;
    padding-top: 20px;
    text-wrap: balance;
    resize: none;
    color: #F3EFE0;
    background-color: #434242;
    margin-top: -7px;
    height: 100%;
}

.mainAIPage .buttonMenu {
    width: 100%;
    height: 15%;
    display: flex;
    color: #F3EFE0;
    background-color: #434242;
    border-radius: 25px;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}


.mainAIPage .actionButton {
    height: 100px !important;
    width: 17% !important;
    margin-left: 2% !important;
    border-radius: 25px !important ;
    color: #434242 !important;
    background-color: #F3EFE0 !important;
    border: none !important;
    text-decoration: none;
    color: #434242 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: -5px;
    font-size: 50px;
}



.mainAIPage .leftButton {
    width: 45% !important;
    padding-left: 20px;
    margin: 0 !important;
}

.mainAIPage .rightButton {
    width: 45% !important;
    padding-left: 10px;
    margin: 0 !important;
}

.mainAIPage .scrollButtons {
    width: 56%;
    display: flex;
    justify-content: space-evenly;
}

.mainAIPage .scrollButtons button {
    height: 100px;
    border-radius: 25px !important ;
    color: #434242 !important;
    background-color: #F3EFE0 !important;
    border: none;
    font-size: 40px;
}

.mainAIPage .nameBox {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    height: 100px;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 25px;
    color: #434242 !important;
    background-color: #F3EFE0;
    border: none;
    align-items: center;
    font-size: 30px;
    font-family: "Nova Mono", monospace !important;

}

.mainAIPage .nameBox H2 {
    /* font-style: italic; */
    color: #434242;
    /* font-family: "Nova Mono", monospace !important; */
    font-size: 40px;
    font-family: "Nunito", sans-serif;
    font-weight: 800;

}

.mainAIPage .line {
    height: 100px;
    width: 2%;
    margin-left: 2%;
    border-radius: 25px;
    background-color: #F3EFE0;
    margin-bottom: 0 !important;
}

.mainAIPage .sendButton {
    width: 20%;
    height: 100%;
    font-size: 50px;
    background-color: #434242;
    border: 0;
    padding-top: 10px;
    border-radius: 25px;
    margin-top: -7px;
    height: 100%;
}


.mainAIPage span {
    font-size: 50px !important;
    color: #F3EFE0;
}

.mainAIPage .material-symbols-outlined {
    font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.mainAIPage .message {
    color: #F3EFE0;
    background-color: #F3EFE0;
    width: 90%;
    height: 150px;
    display: flex;
    border-radius: 25px;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start
}

.mainAIPage .message img {
    margin-left: 20px;
    border-radius: 50%;
    object-fit: cover;
    width: 130px;
    height: 130px;
    object-position: top;
    margin-top: 10px;
    margin-bottom: 10px;

}

.mainAIPage .message h2 {
    width: 80%;
    margin-left: 20px;
    font-family: "Nunito", sans-serif;
    font-weight: 600 !important;
}

@media only screen and (max-width: 700px) {
    .left {
        display: none;
    }

    .right {
        width: 95%;
    }

    .mainAIPage .textField {
        margin-top: 10px;
    }
  }

@media only screen and (max-width: 700px) {
    .left {
        display: none;
    }

    .right {
        width: 90% !important;
    }

    .messages {
        height: 65% !important;
    }

    textarea {
        font-size: 20px !important;
    }

    .message {
        height: 100px !important;
    }

    .message h2 {
        font-size: 18px;
    }

    .message img {
        height: 90px !important;
    }

    .mainAIPage span {
        font-size: 30px !important;
        margin-bottom: 100px !important;
        color: #F3EFE0;
        
    }

    /* .mainAIPage .textField {
        height: 10%;
    } */
    

}


@media only screen and (max-height: 820px) {
   .mainAIPage .actionButton {
        height: 70% !important;
    } 

    .mainAIPage .nameBox {
        height: 70% !important;
    } 

    .mainAIPage .line {
        height: 70% !important;
    } 

    .nameBox h2 {
        font-size: 25px !important;
    }

}