header {
    width: 100%;
} 


.topHeader {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif  !important;
    margin: 0  !important;
    border-top: 1px solid white  !important;
    border-bottom: 1px solid white !important;  
    width: 100%;
    /* margin-top: 25px  !important; */
    /* margin-bottom: 25px  !important; */
    display: flex  !important;
    flex-direction: row  !important;
    flex-wrap: nowrap  !important;
    justify-content: space-between  !important;
    align-items: center  !important;
    /* background-color: #000000  !important; */
    height: 5vh  !important;
    background-color: transparent !important;
    color: white !important;
    margin-bottom: 0 !important;
}


header button {
    background-color: black;
    border: 0;
    height: 100%;
}

header button span {
    color: white;
    font-size: 35px !important;
}


header .leftHeader{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

header.list_items a:hover {
    color: #CF0A0A; 
}


header .rightHeader {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    justify-content: flex-end;
}

header a {
    color: white;
    text-decoration: none;
    font-size: 2em;
    /* margin: 5px; */
    margin-left: 15px;
    margin-right: 15px;
}

header .logoImage {
    height: 100%;
    display: none;
}

header .rightHeader a {
    display: block;
    padding-left: 3%;
    padding-right: 3%;
}

header .rightHeader a:hover{
    color: #CF0A0A;
}

.menuHeaderButton {
    display: none;
}

.bottomHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    display: none;
}

.bottomHeader a {
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    font-family: 'Montserrat', sans-serif  !important;

}

.headerOpen {
    display: flex;
}

@media screen and (max-width: 800px) {


}

@media screen and (max-width: 800px) {
    header .logo{
        display: none;

    }

    header .logoImage {
        display: block;
        /* margin-left: 50%; */
    }

    .menuHeaderButton {
        display: block;
        margin-right: 1%;
    }
    
    
    header .rightHeader {
        display: none;
    }

    header {
        margin-bottom: 20px;
    }

    
}