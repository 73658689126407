.snakeGame {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-family: 'Quicksand', sans-serif;
    background-color: black;
    color: white;
    /* height: 90vh; */
}

.snakeGame header {
    margin-top: 25px !important;
}

.snakeGame .main_collum {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 600px;
    margin-top: 40px;
    border: none;
}

.snakeGame table {
    border-spacing:0; 
    border-collapse: collapse; 
}

.snakeGame tr td {
    border: none !important;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
}

.snakeGame h1 {
    font-size: 50px;
    margin-bottom: -10px;
    font-family: 'Righteous', cursive;
}


.snakeGame .background-color-1 {
    /* background-color: #a4adfd; */
    background-image: url(images/water.jpg);
    background-size: contain;

}

.snakeGame .background-color-2 {
    /* background-color: #919bf8; */
    background-image: url(images/water.jpg);
    background-size: contain;
}


.snakeGame .snake-color-green {
    background-color: #4b5aa2;
    background-image: url(images/snake_color1.jpg);
    border: #4b5aa2 solid 1px;
    color: white;
}

.snakeGame .snake-color-red {
    background-color: #a24b4b;
    background-image: url(images/snake_color2.jpg);

    border: #a24b4b solid 1px;
    color: white;


}

.snakeGame .snake-color-blue {
    background-color: #884ba2;
    background-image: url(images/snake_color3.jpg);

    border: #884ba2 solid 1px;
    color: white;


}

.snakeGame .border {
    /* background-color: #5f5f60; */
    background-image: url(images/stone.jpg);
    border: 1px solid grey;
    background-size: contain;
}

.snakeGame .border {
    /* background-color: #5f5f60; */
    background-image: url(images/stone.jpg);
    border: 1px solid grey;
    background-size: contain;
}

.snakeGame .food {
    /* background-color: #D09CFA; */
    background-image: url(images/fish.jpg);
    background-size: contain;

}

.snakeGame .snake_top {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: -20px;
}

.snakeGame .score_counter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 2px;
}

.snakeGame .score_counter p {
    margin: 0;
}

.snakeGame .buttons {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.snakeGame .buttons button {
    margin: 1%;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    border: white 1px solid;
}

.snakeGame .restart {
    color: white;
    background-color: black;
}

.snakeGame .change_color {
    color: white;
    background-color: #4b5aa2;
}

.snakeGame .main img {
    width: 100%;
}

.snakeGame .settingsButton {
    background-color: black;
    color: white;
}

.snakeGame .settings {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 500px;
    border: white solid 1px;
    border-radius: 15px;
    margin-top: 47px;
    margin-bottom: 49px;

}

.snakeGame .gameRule {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 70px;
}

.snakeGame .plusmin {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 150px;
    justify-content: flex-end;

}

.snakeGame .plusmin button {
    width: 40px;
    /* height: 100%; */
    font-size: 30px;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;

}

.snakeGame .closeButton {
    /* width: 38px; */
    /* height: 100%; */
    font-size: 30px;
    border: 1px solid black;
    border-radius: 85%;
    text-align: center;
    background-color: white;
    color: black;
    position: absolute;
    margin-left: -493px;
    margin-top: -18px;

}

.snakeGame .plusmin p {
    width: 33%;
    height: 100%;
    font-size: 30px;
    text-align: center;
    margin: 7px;
}

.snakeGame .plus {
    background-color: lightgreen;
}

.snakeGame .minus {
    background-color: lightcoral;
}

.snakeGame .settingsInfo {
    opacity: 0.8;
    font-size: 15px;
}

.snakeGame .scores {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-end;
}

.snakeGame .hide {
    display: none;
    position: absolute;
}

