.tictactoeGame {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: black;
    color: white;
    /* margin-top: 50px; */
}

.tictactoeGame header {
    margin-top: 25px !important;
}

.tictactoeGame .game {
    width: 600px;
    margin-top: 50px;
    font-family: 'Bebas Neue', sans-serif;
}

.tictactoeGame h1 {
    font-size: 100px;
    text-align: center;
    margin: 0;
}

.tictactoeGame .score {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -40px;
}

.tictactoeGame th {
    width: 200px;
    height: 200px;
    border: 2px solid white;
    text-align: center;
}

.tictactoeGame tr {
    width: 600px;
}

.tictactoeGame table {
    border-spacing: 0;
    margin-top: 20px;
    font-size: 70px;
    border: 2px solid white;

}

.tictactoeGame .red {
    color: red;
}

#win {
    color: green;
    font-size: 30px;
}

.tictactoeGame .score h2 {
    font-size: 30px;
    margin: 0;
}

.tictactoeGame p {
    font-size: 20px;
    text-align: center;
}

.tictactoeGame .black {
    color: black;
    background-color: white;
}

.tictactoeGame .white {
    color: white;
    background-color: black;
}

.tictactoeGame .blackTable {
    color: white;
    background-color: black;

}

.tictactoeGame .data {
    display: flex;
    flex-direction: column;
}

