.memeGame {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    background-color: black;
    color: white;
}

.memeGame header {
    margin-top: 25px !important;
}

.memeGame .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 700px;
    /* height: 350px; */
    margin-top: 50px;
    font-family: 'Rajdhani', sans-serif;

}

.memeGame .textBox {
    width: 100%;
}

.memeGame .mainText {
    margin: 5px;
    font-size: 30px;
}

.memeGame .main img {
    width: 100%;
    max-height: 550px;
}

.memeGame h1 {
    font-size: 50px;
    margin-top: 50px;
}

.memeGame h3 {
    font-size: 15px;
    margin-top: -26px;
}

.memeGame .mainButton {
    font-size: 40px;
    background-color: black;
    color: white;
    border-radius: 15px;
    border: 3px solid white;
    padding: 22px;
    bottom: 40px;
    position: fixed;
    font-family: 'Rajdhani', sans-serif;

}

.memeGame .adultBox {
    width: 200px;
    height: 105px;
    background-color: black;
    color: white;
    border: 2px solid white;
    border-radius: 15px;
    right: 100px;
    bottom: -20px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-family: 'Rajdhani', sans-serif;

}

.memeGame .adultBox h3 {
    margin-top: -10px;
    font-size: 30px;
}

.memeGame .adultBox h2 {
    margin-top: 5px;
}

.memeGame .red {
    color: rgb(255, 0, 0);
}

.memeGame .green {
    color: rgb(21, 207, 21);
}

.memeGame .hide {
    display: none;
}

@media screen and (max-width: 440px) {


    .memeGame .mainButton {
        font-size: 20px;
        padding: 22px;
        bottom: 100px;    
    }

    .memeGame .main img {
        min-width: 100%;
        max-height: 250px;
        object-fit: contain;
    }

    .memeGame .mainText {
        margin: 5px;
        font-size: 15px;
    }

    .memeGame .main {
        width: 90%;
    }

    .memeGame {
        margin-top: 100px;
    }

    
    .memeGame .adultBox {
        width: 180px;
        height: 77px;
        right: 12px;
        bottom: -22px;
    }

    .memeGame .adultBox h3 {
        font-size: 15px;
    }
}