.cubeGame {
    user-select: none;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.cubeGame .startScreen {
    width: 100%;
    height: 100vh;
    background-color: #929AAB;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: Nunito;
}

.cubeGame .startScreen .name {
    font-size: 80px;
    margin: 0;
    color: white;
    /* -webkit-text-stroke-color: #393E46;
    -webkit-text-stroke-width: 1px; */
    /* font-family: 'Montserrat', sans-serif; */
}

.cubeGame .startScreen .startButton {
    font-family: Nunito;
    font-size: 40px;
    color: white;
    background-color: #393E46;
    border: 3px solid #393E46;
    border-radius: 15px;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
}

/* .level {
    font-family: 'Nunito', sans-serif;
    font-size: 60px;
    position: fixed;
    left: 20px;
    top: 20px;
} */

.cubeGame .gameOver {
    width: 100%;
    height: 100vh;
    background-color: #929AAB;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-family: Nunito;
}

.cubeGame .gameOver .name {
    font-size: 120px;
    margin: 0;
    margin-top: 360px;
    color: white;
    /* -webkit-text-stroke-color: #393E46;
    -webkit-text-stroke-width: 1px; */
    /* font-family: 'Montserrat', sans-serif; */
}

.cubeGame .gameOver .hint {
    color: #282b31;
}


.cubeGame .material-symbols-outlined {
  font-variation-settings:
  'FILL' #ffffff,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
  font-size: 40px !important ; 
}

.cubeGame .settings {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
}

.cubeGame .settingsHolder {
    width: 100%;
    height: 100vh;
    background-color: #929AAB;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.cubeGame .settingsHolder .settingsBox {
    width: 500px;
    height: 500px;
    background-color: transparent;
    display: flex;
    /* border: 3px solid white; */
    border-radius: 15px;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.cubeGame .option {
    width: 90%;
    height: 100px;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-size: 40px !important;
    border-radius: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.cubeGame .option h2 {
    font-size: 40px;
    font-family: Nunito;
    margin-left: 25px;
    margin-bottom: 0 !important;

}

.cubeGame .option .material-symbols-outlined {
    font-variation-settings:
    'FILL' #ffffff,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
    font-size: 62px !important ; 
    margin-right: 15px;
    margin-top: 10px;

}

.cubeGame .sgLink {
    background-color: black;
    border: white;
    justify-content: center !important;
    border-radius: 15px;
    text-align: center;
    text-decoration: none;
    color: white;
}

.cubeGame .topSettings {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 100px;
    margin-bottom: 5px;
    margin-top: 35px;
}

.cubeGame .skipLevel {
    width: 40%;
    height: 100%;
    color: black;
    background-color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
}

.cubeGame .skipLevel span {
    margin-top: 0px !important;
}

.cubeGame .closeButton {
    background-color: white;
    color: black;
    border: white;
    justify-content: center !important;
    border-radius: 15px;
    text-align: center;
    text-decoration: none;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;

}

.cubeGame .closeButton span {
    margin-right: 0 !important;
    margin-top: 0 !important;
    color: black;
}

.hide {
    display: none !important;
}
