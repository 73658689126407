/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}

/* Handle */
  ::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 10px;
  border: 2px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: red; 
  border: 2px solid red; */

}