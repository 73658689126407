.gamesPage{
    margin: 0;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    color: white;
    padding-top: 25px;
    background: black;  

}



.gamesPage .gameHolder{
    text-decoration: none;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    background-color: transparent;
    overflow: hidden; 
    justify-content: flex-start;
    height: 100%;
    color: black;
    /* background-color: white; */
}  

.gamesPage .bottomText {
    background-color: transparent;
    text-decoration: none;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}


.gamesPage .gameHolder img{
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: center;
    -webkit-user-select: none;
    margin-top: 0;
    z-index: 0;
}


.gamesPage .highLight {
    color: red;
}

.gamesPage ul {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    height: 94%;
}


.gamesPage .gameHolder h4 {
    font-size: 15px;
    margin-right: 30px;
    margin-left: 30px;
    text-align: left;
}

.gamesPage .bottomText h2 {
    font-size: 50px;
    margin: 0;
    margin-left: 30px;
    margin-top: 5px;
    font-family: 'Rajdhani', sans-serif;
}

.gamesPage .description {
    width: 100%;
    border-radius: 25px;
    background-color: transparent;
}

.gamesPage ul li {
    width: 40%;
    margin-left: 1%; 
    margin-right: 1%;
    height: 44%;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;

}

.gamesPage li a {
    text-decoration: none;
    color: transparent;
}

.gamesPage .underImage {
    width: 100%;
    z-index: 2;
    background-color: #EEEEEE;
    height: 20%;    
    color: black;
}

.gamesPage .checked {
    color: black;
    font-variation-settings:
    'FILL' 1
}


.gamesPage .stars {
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: black;
}

.gamesPage .stars span {
    font-size: 35px;
    margin: 3px;
}

@media screen and (max-width: 1500px) {
    .gamesPage .bottomText h2 {
        font-size: 35px;
    }

    .gamesPage .stars span {
        font-size: 25px;
    }

}

@media screen and (max-width: 1100px) {
    .gamesPage .bottomText h2 {
        font-size: 25px;
    }

    .gamesPage .stars span {
        font-size: 20px;
    }

}

@media screen and (max-width: 800px) {
    .gamesPage ul {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: space-around;
        align-items: center;
        height: 100%;
    }

    .gamesPage ul li {
        margin-bottom: 2%;
        margin-top: 2%;
        width: 80%;
        
    }

    .gamesPage .bottomText h2 {
        font-size: 30px;
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    
    .gamesPage .stars span {
        display: none;
    }
    
    
}

@media screen and (max-width: 400px) {
    .gamesPage .description {
        display: none;
    }

    .gamesPage .gameHolder {
        height: 100%;
    }

   

    .gamesPage .bottomText h2 {
        font-size: 20px;
        margin-left: 0px !important;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
    }

    
}

@media screen and (max-height: 1000px) {

    .gamesPage .bottomText h2 {
        font-size: 30px;
    }

    .gamesPage .stars span {
        font-size: 25px;
    }
}